<template>
  <div class="mt-4">
    <p class="titleSection">
      {{ $t('new_onboarding.stepThree.title') }}
    </p>
    <div class="formArea">
      <v-form ref="location">
        <v-row>
          <v-col cols="12" lg="6">
            <v-autocomplete placeholder="تایپ کنید..." v-model="user.country_id" :items="contires" item-text="label"
                            item-value="id"
                            :rules="requiredRules" outlined
                            style="border-radius: 12px"
                            :label="$t('new_onboarding.stepThree.country')"></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model="user.city" :rules="requiredRules" outlined style="border-radius: 12px"
                          :label="$t('new_onboarding.stepThree.city')"></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-autocomplete v-model="user.tz" :rules="requiredRules" item-text="text" item-value="value"
                            :items="timezonesList" outlined
                            style="border-radius: 12px"
                            hint="زمان های نمایش‌ داده بر اساس این منطقه زمانی می‌باشد"
                            persistent-hint
                            :label="$t('new_onboarding.stepThree.tz')"></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="6">
            <v-select v-model="user.knway" :items="kenway" item-value="value" item-text="title" :rules="requiredRules"
                      outlined style="border-radius: 12px"
                      :label="$t('new_onboarding.stepThree.kenway')"></v-select>

            <div class="" v-if="user.knway == 'other'">
              <v-text-field style="border-radius: 12px"
                            v-model="user.knway_meta" :rules="requiredRules" outlined class="profileTextFiled"
                            :label="$t('knwayLabel')" :items="knway" item-text="title"
                            item-value="value"></v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>

  </div>
</template>

<script>

import moment from "moment-timezone";

export default {
  props: {
    submiting: Boolean,
    user: Object,
  },
  data() {
    return {
      timezonesList: null,
      kenway: [
        {value: "google", title: this.$t('knway.google')},
        {value: "twitter", title: this.$t('knway.twitter')},
        {value: "instagram", title: this.$t('knway.instagram')},
        {value: "podcast", title: this.$t('knway.podcast')},
        {value: "bannerads", title: this.$t('knway.bannerads')},
        {value: "friends", title: this.$t('knway.friends')},
        {value: "youtube", title: this.$t('knway.youtube')},
        // {value: "site", title: this.$t('knway.site')},
        {value: "other", title: this.$t('knway.other')},
      ]
    }
  },
  mounted() {

    let times = JSON.parse(JSON.stringify(this.timezones));
    this.timezonesList = times.map((item) => {
      item.text = item.text + '  ' + moment.tz(item.text).format('Z');
      return item;
    });
    this.prepareDefaultTimezone()

  },
  methods: {
    prepareDefaultTimezone() {
      try {
        if (this.user) {
          let options = {timeZoneName: 'long'};
          let timezone = Intl.DateTimeFormat(undefined, options).resolvedOptions().timeZone;
          if (timezone) {
            console.log(timezone, '21212')
            if (this.user.tz == 'UTC') {
              this.user.tz = timezone
            }
          }
        }
      } catch (er) {
        console.log(er)
      }
    }
  },
  computed: {},
  watch: {
    submiting() {
      if (this.$refs.location.validate()) {
        this.$emit('nextHandler', 1)
      }
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .formArea {
    width: 100% !important;
    margin: 25px auto 0px auto !important;
  }

  .titleSection {
    font-size: 15px !important;
    margin-top: 20px !important;
  }

}

.titleSection {
  font-size: 16px;
}

.formArea {
  width: 60%;
  margin: 30px auto 0px auto;
}
</style>