<template>
  <div>
    <p class="titleSection mt-5">
      {{ $t('new_onboarding.stepFour.title') }}
    </p>
    <div class="formArea">
      <v-form ref="cycle">
        <v-select v-model="cycle.heading" :rules="requiredRules" :items="categories" style="border-radius: 12px"
                  outlined
                  :label="$t('new_onboarding.stepFour.category')"></v-select>

<!--        <v-textarea v-model="cycle.description" :rules="requiredRules" style="border-radius: 12px" outlined-->
<!--                    :label="$t('new_onboarding.stepFour.desc')"></v-textarea>-->
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categories: [
        this.$t('addCycleModal.categories[0]'),
        this.$t('addCycleModal.categories[1]'),
        this.$t('addCycleModal.categories[2]'),
        this.$t('addCycleModal.categories[3]'),
        this.$t('addCycleModal.categories[4]'),
        this.$t('addCycleModal.categories[5]'),
      ],
    }
  },
  props: {
    submiting: Boolean,
    cycle: Object,
  },
  watch: {
    submiting() {
      if (this.$refs.cycle.validate()) {
        this.$emit('nextHandler', 1)
      }
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .formArea {
    width: 100% !important;
    margin: 25px auto 0px auto !important;
  }

  .titleSection {
    font-size: 15px !important;
    margin-top: 30px !important;
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .formArea {
    width: 80% !important;
  }

}

.titleSection {
  font-size: 20px;
}

.formArea {
  width: 30%;
  margin: 50px auto 0px auto;
}
</style>