<template>
  <div :class="$vuetify.breakpoint.mdAndDown ? 'mt-10' : 'mt-4'">
    <p class="titleSection">
      {{ $t('new_onboarding.stepTwo.title') }}
    </p>
    <div class="formArea">
      <v-form ref="personalForm">
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field v-model="user.firstname" :error="false" :rules="requiredRules" outlined
                          style="border-radius: 12px"
                          :hint="$t('profileView.name_hint')"
                          persistent-hint
                          :label="$t('new_onboarding.stepTwo.name')"></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field v-model="user.lastname" :rules="requiredRules" outlined style="border-radius: 12px"
                          :label="$t('new_onboarding.stepTwo.family') "></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="ltrInput">
              <v-text-field v-model="user.en_name" :error="false" :rules="requiredRules" outlined
                            style="border-radius: 12px"
                            :label="$t('profileView.en_name')"></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="ltrInput">
              <v-text-field v-model="user.en_family" :rules="requiredRules" outlined style="border-radius: 12px"
                            :label="$t('profileView.en_family') "></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="phoneNumber">
              <v-text-field v-model="phone" :rules="phoneRules" outlined style="border-radius: 12px;"
                            :label="$t('new_onboarding.stepTwo.tell')"></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" lg="6" @click="showDialog=!showDialog;isJalali = true">
            <v-text-field v-model="user.birthDate" :rules="requiredRules" outlined
                          style="border-radius: 12px"
                          :label="$t('new_onboarding.stepTwo.birthDay')"></v-text-field>
            <DatePicker @close="showDialog=false"
                        :dialog="showDialog"
                        @jalaliChange="changeIsJalali"
                        @input="showDialog = false"
                        v-model="user.birthDate"
            ></DatePicker>
          </v-col>
        </v-row>
        <!--        <p class="text-right">-->
        <!--          اطلاع رسانی جلسات از طریق:-->
        <!--        </p>-->
        <!--        <div class="d-flex">-->
        <!--          <v-checkbox readonly v-model="notifyEmail" label="ایمیل"></v-checkbox>-->
        <!--          <v-checkbox label="پیامک (SMS)" class="mx-8"></v-checkbox>-->
        <!--        </div>-->
      </v-form>
    </div>
  </div>
</template>

<script>
import AuthMixins from "@/mixins/AuthMixins";
import DatePicker from "@/components/Calendar/DatePicker";

export default {
  components: {DatePicker},
  mixins: [AuthMixins],
  props: {
    user: Object,
    submiting: Boolean,
  },
  methods: {
    changeIsJalali(val) {
      this.isJalali = val;
    },
    back() {
      this.$emit('prevHandler')
    }
  },
  data() {
    return {
      phone: null,
      showDialog: false,
      notifyEmail: true,
      isJalali: false,
    }
  },
  mounted() {
    this.phone = this.user.cel
    this.$emit('changeIsJalali', this.isJalali)
    // setInterval(() => {
    //   console.log(this.submiting, 'hrere')
    // }, 500)
  },
  updated() {

  },
  watch: {
    phone() {
      this.user.cel = this.phone
    },
    submiting() {
      if (this.$refs.personalForm.validate()) {
        this.$emit('nextHandler', 1)
      }
    },
    isJalali(val) {
      this.$emit('changeIsJalali', val)
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .formArea {
    width: 100% !important;
    margin: 25px auto 0px auto !important;
  }

  .titleSection {
    font-size: 15px !important;
    margin-top: -30px !important;
  }

}

.titleSection {
  font-size: 16px;
}

.formArea {
  width: 60%;
  margin: 20px auto 0px auto;
}

</style>