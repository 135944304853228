<template>
  <div class="gorgianCalendar en_light">
    <v-date-picker style="height: 250px;z-index: 1;font-family: en-light !important; " v-model="internalValue" :picker-date.sync="pickerDate" no-title
                   :events="events" event-color="transparent" @change="loadCalenderEvents"
                   @input="updateParentValue">

    </v-date-picker>
  </div>
</template>

<script>
/*eslint-disable*/
import moment from "moment-jalaali";

export default {
  props: {
    value: {
      // type: ,
      required: true
    },
    times: Object,
  },
  data() {
    return {
      internalValue: null,
      pickerDate: null
    };
  },
  computed: {
    events() {
      let temp = []
      if (this.times) {
        // this.times.forEach(item => {
        //   temp.push(moment(item.date).format('YYYY-MM-DD'))
        // })
        Object.keys(this.times).forEach(key => {
          temp.push(moment(key).format('YYYY-MM-DD'))
        })
      }
      this.loadCalenderEvents()
      return temp
    }
  },
  mounted() {
    this.internalValue = this.value;
  },
  methods: {
    loadCalenderEvents() {
      setTimeout(() => {
        const btns = document.querySelectorAll('button.v-btn');
        btns.forEach((btn) => {
          const eventsDiv = btn.querySelector('div.v-date-picker-table__events');
          if (eventsDiv) {
            btn.classList.add('dayWithEvent');
          }
        });
      }, 500)
    },
    updateParentValue(newValue) {
      this.$emit('selectDay')
      this.internalValue = newValue;
      this.$emit('input', newValue);
    }
  },
  watch: {
    pickerDate() {
      this.loadCalenderEvents()
    }
  }
};
</script>

<style>

.gorgianCalendar .v-btn__content{
  font-family: en-light !important ;
}
.gorgianCalendar .accent--text button{
  font-family: en-light !important ;
}
.gorgianCalendar .v-date-picker-title__year{
  font-family: en-light !important ;
}
tbody .accent--text {
  font-weight: 700;
  background: #3CBE8B !important;
  color: white !important;
  border: none !important;
  border-width: 1px;
}

tbody .accent--text .v-btn__content {
  color: white;
}
.gorgianCalendar  .dayWithEvent {
  background: #f6fff7 !important;
  border: 2px solid #3CBE8B;
}

.dayWithEvent .warning {
  display: none;
}
</style>