<template>
  <div class="">
    <div class="indicationArea d-flex justify-space-between flex-row-reverse">
      <div class="indicationItem">
        <div class="indicationIcon" :class="step >= 5 && 'activeIndicatorIcon'"></div>
        <div class="indicationText " :class="step >= 5 && 'activeIndicatorText'">
          پیش مشاوره
        </div>
      </div>
      <div class=" mt-3">
        <div class="line"></div>
      </div>
      <div class="indicationItem">
        <div class="indicationIcon" :class="step >= 4 && 'activeIndicatorIcon'"></div>
        <div class="indicationText " :class="step >= 4 && 'activeIndicatorText'">
          {{$t('new_onboarding.idicatior.cycle')}}
        </div>
      </div>
<!--      <div class="mt-3">-->
<!--        <div class="line"></div>-->
<!--      </div>-->
<!--      <div class="indicationItem">-->
<!--        <div class="indicationIcon" :class="step >= 3 && 'activeIndicatorIcon'"></div>-->
<!--        <div class="indicationText" :class="step >= 3 && 'activeIndicatorText'">-->
<!--          {{$t('new_onboarding.idicatior.location')}}-->
<!--        </div>-->
<!--      </div>-->
      <div class=" mt-3">
        <div class="line"></div>
      </div>
      <div class="indicationItem">
        <div class="indicationIcon" :class="step >= 2 && 'activeIndicatorIcon'"></div>
        <div class="indicationText " :class="step >= 2 && 'activeIndicatorText'">
          {{$t('new_onboarding.idicatior.info')}}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    step: Number,
  }

}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .line {
    width: 50px !important;
  }
  .indicationArea {
    width: 100% !important;
  }
  .indicationText{
    font-size: 9px !important;
  }
  .indicationIcon{
    width: 18px !important;
    height: 18px !important;
  }
}

.line {
  width: 100px;
  height: 1px;
  background: #4597DF;
}

.activeIndicatorIcon {
  background: #D8E9F8;
  transition: all 0.3s;
}
.activeIndicatorText{
  color: #4597DF;
}

.indicationText {
  font-size: 12px;
  /*margin-left: 15px;*/
  margin-top: 3px;
  text-align: center;
}

.indicationIcon {
  border: 1px solid #4597DF;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: all 0.3s;
  margin: 0px auto;

}

.indicationArea {
  width: 50%;
  margin: 0px auto;
  position: relative;
  z-index: 9;
}

.indicationItem {
  /*display: flex;*/
}
</style>