<template>
  <div class="OnboardingBaseArea fill-height">
    <div class="OnboardingArea white">
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <!--        <img class="sideOne" src="../../assets/onboarding/Right_up.svg" alt="">-->
        <!--        <img class="sideTwo" src="../../assets/onboarding/Left_down.svg" alt="">-->
      </template>
      <template v-else>
        <!--        <img class="sideOneMob" src="../../assets/onboarding/up_mobile.svg"/>-->
        <!--        <img class="sideTwoMob" src="../../assets/onboarding/down_mobile.svg"/>-->
      </template>
      <div class="contentArea fill-height d-flex flex-column" v-if="activeStep">
        <div>
          <v-fade-transition>
            <template v-if="activeStep.id != 1">
              <div class="mb-5">
                <IndicatorNew :step="activeStep.step"/>
              </div>
            </template>
          </v-fade-transition>
        </div>

        <div class="flex-grow-1">
          <div class="fill-height text-center" v-for="(step , i) in renderList" :id="`step_${step.step}`"
               :class="step.step != activeStep.step && 'HideStep'" :key="i">
            <v-fade-transition>
              <component :cycle="cycle"
                         :user="profile"
                         :submiting="submiting"
                         :is="step.component"
                         v-bind="step.props"
                         @prevHandler="prevHandler($event)"
                         @startHandler="startProcess"
                         @nextHandler="nextHandler($event)"
                         @changeIsJalali="changeIsJalali($event)"
                         :stepID="activeStep.step" v-if="step.step == activeStep.step"></component>
            </v-fade-transition>
          </div>
        </div>
        <div>
          <template v-if="activeStep.id != 1 && activeStep.id != 5 && activeStep.id != 6 ">
            <v-fade-transition>
              <div class="ActionArea">
                <v-row dir="rtl">
                  <v-col cols="6" lg="6" class="d-flex justify-end">
                    <v-btn class="NextBtn" color="#3CBE8B" elevation="0" @click="goToNext" :disabled="loadingStatus">
                      <img src="../../assets/onboarding/Next.png" width="8px" class="ml-2" alt=""
                           style="transform: rotate(0deg)">
                      <span>
                                              {{ $t('new_onboarding.next') }}
                      </span>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" lg="6" class="d-flex justify-start">
                    <v-btn text class="PrevBtn" color="#4597DF" :disabled="loadingStatus" elevation="0"
                           @click="prevHandler">
                      {{ $t('new_onboarding.prev') }}
                      <img src="../../assets/onboarding/Prev.png" width="5px" class="mr-2" alt=""
                           style="transform: rotate(360deg)">
                    </v-btn>
                  </v-col>

                </v-row>
              </div>
            </v-fade-transition>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StartStep from "@/components/OnBoarding/New/StartStep";
import PersonalInfo from "@/components/OnBoarding/New/PersonalInfo";
import gsap from "gsap";
import IndicatorNew from "@/components/OnBoarding/New/IndicatorNew";
import {mapGetters} from "vuex";
import LocationInfo from "@/components/OnBoarding/New/LocationInfo";
import CycleInfo from "@/components/OnBoarding/New/CycleInfo";
import SelectDateTimeSession from "@/components/OnBoarding/New/SelectDateTimeSession";
import AddToCalendar from "@/components/OnBoarding/New/AddToCalendar";
import EventsMixins from "@/mixins/EventsMixins";
import moment from "moment-jalaali";
/*eslint-disable*/
export default {
  components: {AddToCalendar, SelectDateTimeSession, CycleInfo, LocationInfo, IndicatorNew, PersonalInfo, StartStep},
  mixins: [EventsMixins],

  computed: {
    ...mapGetters({
      'canSubmit': 'getCanSubmit',
      'profile': 'getProfile',
      'AppUrl': 'getAppUrl',
    }),
    stepList() {
      return [
        {
          id: 1,
          component: 'StartStep',
          dataComponent: [],
          animation: 'slide',
          key: 'start',
          is_form: false,
          props: {}
        },
        {
          id: 2,
          component: 'PersonalInfo',
          dataComponent: [],
          animation: 'slide',
          key: 'personal_info',
          is_form: true,
          props: {}
        },
        {
          id: 3,
          component: 'LocationInfo',
          dataComponent: [],
          animation: 'slide',
          key: 'location_info',
          is_form: true,
          props: {}
        },
        {
          id: 4,
          component: 'CycleInfo',
          dataComponent: [],
          animation: 'slide',
          key: 'cycle_info',
          is_form: true,
          props: {}
        },
        {
          id: 5,
          component: 'SelectDateTimeSession',
          dataComponent: [],
          animation: 'slide',
          key: 'time',
          is_form: false,
          props: {}
        },
        {
          id: 6,
          component: 'AddToCalendar',
          dataComponent: [],
          animation: 'slide',
          key: 'add_to_calender',
          is_form: false,
          props: {
            preSession: true,
            actionText: 'رفتن به داشبورد'
          }
        },
      ]
    },
    renderList() {
      let step = 1;
      return this.stepList.filter(item => {
        // console.log('generated')
        // if (this.profile.requiredFields.length == 0) {
        //   if (item.id == 2 || item.id == 3) {
        //     return;
        //   }
        // }
        //
        //
        // if (this.profile.cycles.length != 0) {
        //   if (item.id == 4) {
        //     return;
        //   }
        // }

        item.step = step
        step++;
        return item;

        // }
      })
    },
    // user() {
    //   return JSON.parse(JSON.stringify(this.profile))
    // },
  },
  data() {
    return {
      startStep: 2,
      nextLock: false,
      prevLock: false,
      activeStep: null,
      submiting: false,
      // profile: null,
      cycle: {
        heading: null,
        description: null,
      },
      isJalali: false,
    }
  },
  created() {
    // this.profile = JSON.parse(JSON.stringify(this.profileStore))
  },
  mounted() {
    // console.log(this.loadingStatus,'12121')
    //
    // console.log(this.renderList)
    // this.user = JSON.parse(JSON.stringify(this.profile))
    this.activeStep = this.renderList.find(item => item.step === 1)


  },
  methods: {
    changeIsJalali(val) {
      this.isJalali = val
    },
    startProcess() {
      // if (!this.nextLock) {
      //   this.nextLock = true
      // //   let nextStep = 2;
      this.nextHandler();
      // if (this.profile.requiredFields.length == 0) {
      //   if (this.profile.cycles.length != 0) {
      //     this.startStep = 5;
      //     this.nextHandler()
      //     return;
      //   } else {
      //     this.startStep = 4;
      //     this.nextHandler()
      //     return;
      //   }
      // } else {
      //   this.nextHandler()
      // }
      // this.startStep = 6
      // this.nextHandler()

      // else if (this.profile.cycles.length != 0) {
      //   this.startStep = 5;
      // }else{
      //
      // }


      //

    },
    prevHandler(stepId) {
      if (!this.prevLock) {
        this.prevLock = true
        gsap.fromTo(`#step_${this.activeStep.step}`, {x: 0, display: 'block', opacity: 1}, {
          x: 100,
          display: 'none',
          opacity: 0,
        }).then(() => {
          this.activeStep = this.renderList.find(item => item.step === (this.activeStep.step - 1))
          // setTimeout(() => {
          gsap.fromTo(`#step_${this.activeStep.step}`, {x: -100, display: "none", opacity: 0}, {
            x: 0,
            display: 'block',
            opacity: 1
          }).then(() => {
            this.prevLock = false
          })
          // }, 300)
        })
      }

    },

    canSubmitForm() {
      this.submiting = true
      return this.canSubmit
    },
    goToNext() {
      this.submiting = !this.submiting
    },
    saveProfile(callBack = () => {
    }) {
      let cel = this.profile.cel.replaceAll(' ', '')
      let birth_date = moment(this.profile.birthDate, 'jalali');
      console.log(this.isJalali)
      let data = {
        // birth_date: moment(this.userInfo.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        birth_date: this.isJalali ? this.jalaliToGregorian(this.profile.birthDate) : this.profile.birthDate,
        name: this.profile.firstname,
        family: this.profile.lastname,
        mobile: cel,
        timezone: this.profile.tz,
        "familiar_by": this.profile.knway,
        "familiar_by_meta": this.profile.knway_meta,
        "city": this.profile.city,
        "country_id": this.profile.country_id,
        "marital_state": this.profile.married,
        "education_level": this.profile.edu,
        "job": this.profile.job,
        "en_name": this.profile.en_name,
        "en_family": this.profile.en_family,

      };
      this.$emitEvent('setLoading', true)
      this.requesting('auth', 'editProfile', '', data).then((resp) => {
        this.WebengageSetUserRequiredFiled(resp.data.profile)
        this.$emitEvent('notify', {
          title: this.$t('userOnboarding.notify.done'),
          color: 'green',
          id: 'notify_success'
        })
        callBack()
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    updateProfile(callBack = () => {
    }) {
      this.$emitEvent('setLoading', true)
      this.requesting('auth', 'getProfile').then(() => {
        callBack()
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    nextHandler(stepId) {
      if (!this.nextLock) {
        this.nextLock = true
        // console.log(this.activeStep.id)
        switch (this.activeStep.id) {
          case 1:
            this.nextAnimationHandler(this.startStep)
            break;
          case 3:
            this.saveProfile(this.nextAnimationHandler)
            break;
          case 4:
            this.storeCycle(this.nextAnimationHandler)
            break;
          case 5 :
            this.updateProfile(this.redirectToDashboardOrAddToCalender)
            break;
          case 6 :
            this.redirectToDashBoard()
            break;
          default:
            this.nextAnimationHandler();
            break;
        }


      }
    },
    redirectToDashboardOrAddToCalender() {
      if (this.$store.getters.getSelectedTime == null) {
        this.redirectToDashBoard()
      } else {
        this.nextAnimationHandler()
      }
    },
    redirectToDashBoard() {
      // this.$router.push({name: 'dashboard', query: {from: 'onboarding'}})
      window.location.href = `${this.AppUrl}/fa/panel/dashboard?from=onboarding`

    },
    storeCycle(callBack = () => {
    }) {
      let payload = {
        title: this.cycle.heading,
        // description: this.cycle.description,
      }
      this.$emitEvent('setLoading', true)
      this.requesting('user', 'insertCycle', '', payload).then(() => {
        this.requesting('auth', 'getProfile').then(() => {
          // this.$emitEvent('notify', {title: this.$t('select_cycle.notify.addit'), color: 'green'})
          callBack()
        })
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    nextAnimationHandler(nextStep = null) {
      this.$nextTick().then(() => {
        gsap.fromTo(`#step_${this.activeStep.step}`, {x: 0, display: 'block', opacity: 1}, {
          x: -100,
          display: 'none',
          opacity: 0
        },).then(() => {
          this.activeStep = this.renderList.find(item => item.step == (nextStep ? nextStep : (this.activeStep.step + 1)))
          // console.log(this.activeStep)
          gsap.fromTo(`#step_${this.activeStep.step}`, {x: 100, opacity: 0, display: 'none'}, {
            x: 0,
            opacity: 1,
            display: 'block'
          }).then(() => {
            this.nextLock = false
          })
        })
      })
    },
  },

}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {

  .OnboardingArea {
    width: 100% !important;
    height: 100% !important;
  }

  .sideOne {
    display: none !important;
  }

  .sideTwo {
    display: none;
  }

  .sideOneMob {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .sideTwoMob {
    position: absolute;
    bottom: 0px;
    left: 0px;
  }

  .ActionArea {
    width: 100% !important;
  }

  .contentArea {
    padding: 40px 10px !important;
  }

  .NextBtn {
    width: 100% !important;
  }

  .PrevBtn {
    width: 100% !important;
  }

}


.PrevBtn {
  border-radius: 9px !important;
  width: 120px;
  height: 30px !important;
  font-size: 15px;
}

.ActionArea {
  width: 60%;
  margin: 0px auto;
  text-align: center;
}

.NextBtn {
  border-radius: 9px !important;
  width: 120px;
  height: 30px !important;
  font-size: 15px;
  color: white;
}

.OnboardingBaseArea {
  background: #EBFAF4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.OnboardingArea {
  width: 70%;
  margin: 0px auto;
  padding: 20px;
  border-radius: 15px;
  min-height: 80%;
  position: relative;
}

.sideOne {
  position: absolute;
  top: 0px;
  right: 0px;
}

.sideTwo {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.contentArea {
  padding: 40px;
}

.HideStep {
  display: none;
}


</style>