<template>
  <div class="fill-height d-flex flex-column justify-space-between">
    <div>
      <img src="../../../assets/onboarding/Logo.png" alt="">
    </div>
    <div class="contentSection">
      <p style="" v-if="$route.name == 'onboarding_new'">
        همراه عزیز
        <br>
        جلسه پیش‌مشاوره، پس از تکمیل اطلاعات تنظیم می‌شود.
        جلسات سیمیاروم به صورت آنلاین و از طریق Skype برگزار می‌شوند.
        <br>
        توجه داشته باشید که پیش از شروع جلسات، یک جلسه پیش مشاوره خواهید داشت.
        <br>
        این جلسه گفت‌وگویی 15 دقیقه‌ای بین شما و یکی از کارشناسان روانشناس سیمیاروم است
        که کمک می‌کند مناسب‌ترین مشاور به شما معرفی شود.
        <!--        {{ $t('new_onboarding.stepOne.text[0]') }}-->
        <!--        <br>-->
        <!--        {{ $t('new_onboarding.stepOne.text[1]') }}-->
        <!--        <span style="color: #4597DF">-->
        <!--                        {{ $t('new_onboarding.stepOne.text[2]') }}-->
        <!--              </span>-->
        <!--        {{$t('new_onboarding.stepOne.text[3]')}}-->
        <!--        <br>-->
        <!--        {{$t('new_onboarding.stepOne.text[4]')}}-->
      </p>
      <template v-else-if="$route.name == 'onboarding_presession'">
        <p>
          باتشکر
          <br>
          همراه عزیز
          شما می‌توانید زمان مناسب خود را برای برگزاری جلسه از بین زمان های آزاد متخصصین تیم پیش مشاوره انتخاب و ثبت
          نمایید.
        </p>
        <p style="font-size: 15px;margin-top: 10px" class="font-weight-bold">
          توجه کنید که تنها
          <u>
            یک بار
          </u>
          فرصت استفاده از پیش مشاوره رایگان را خواهید داشت.
        </p>
      </template>

    </div>

    <div class="actionArea">
      <v-btn class="startBtn" elevation="0" @click="start">
        {{ $t('new_onboarding.stepOne.action') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stepID: null,
  },
  data() {
    return {}
  },
  methods: {
    start() {
      this.$emit('startHandler')
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .contentSection p {
    font-size: 15px !important;
  }
}

.contentSection p {
  font-size: 18px;
  line-height: 26pt;
}

.startBtn {
  background: #3CBE8B !important;
  color: white;
  width: 256px;
  height: 45px !important;
  border-radius: 10px !important;
  margin: 0px auto;
}

</style>
